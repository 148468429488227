<template>
  <div class="loan_item">
    <div class="inner">
      <h3>{{ $t('aboutUs.title') }}</h3>
      <div class="left_cell">
        <p>{{ $t('aboutUs.p1') }}</p>
        <br>
        <p>{{ $t('aboutUs.p2') }}</p>
        <br>
        <p>{{ $t('aboutUs.p3') }}</p>
        <br>
        <p>{{ $t('aboutUs.p4') }}</p>
        <p>{{ $t('aboutUs.p5') }}</p>
      </div>
      <div class="right_cell">
        <img src="@/assets/images/loanImg/bn_n.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 36px;
      color: #333333;
    }
    .left_cell{
      float: left;
      width: 50%;
      padding: 60px 0;
      p{
        font-size: 16px;
        line-height: 30px;
        color: #999999;
      }
      ul{
        padding-top: 60px;
        li{
          float: left;
          width: 188px;
          height: 132px;
          padding-top: 50px;
          text-align: center;
          font-size: 16px;
          border-radius: 4px;
          border: 1px solid #f1f1f1;
          color: #333333;
          box-shadow: 0px 1px 10px 1px #f1f1f1;
          margin-right: 40px;
          margin-left: 5px;
        }
      }

    }
    .right_cell{
      float: left;
      width: 50%;
      padding: 60px 0;
      img{
        width: 567px;
      }

    }
  }

}
</style>
